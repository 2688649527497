import React from "react"
import { useTranslation } from "react-i18next"
import { Grid, Container, Box, Divider, Paper } from "@material-ui/core"
import "../styles/general.css"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { red } from "@material-ui/core/colors"
import FavoriteIcon from "@material-ui/icons/Favorite"
import ShareIcon from "@material-ui/icons/Share"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MoreVertIcon from "@material-ui/icons/MoreVert"

import ImgUnisabanaH from "../components/imgComponents/imgUnisabanaH"
import ImgHospitalKennedy from "./imgComponents/imgHospitalKennedy"
import ImgHospitalTunal from "./imgComponents/imgHospitalTunal"
import ImgHospitalSanBlas from "./imgComponents/imgHospitalSanBlas"
import ImgHospitalSanRafaelFaca from "./imgComponents/imgHospitalSanRafaelFaca"
import ImgHospitalSamaritanaZipa from "./imgComponents/imgHospitalSamaritanaZipa"
import ImgHospitalTunjuelito from "./imgComponents/imgHospitalTunjuelito"
import ImgSecretariaSalud from "./imgComponents/imgSecretariaSalud"
import ImgHospitalSanRafaelFusa from "./imgComponents/imgHospitalSanRafaelFusa"
import ImgClinicaShaio from "./imgComponents/imgClinicaShaio"
import ImgInc from "./imgComponents/imgInc"
import ImgClinicaLaSabana from "./imgComponents/imgClinicaLaSabana"
import ImgAmiPallium from "./imgComponents/imgAmiPallium"
import ImgBienestarIps from "./imgComponents/imgBienestarIps"
import ImgCardioInfantilsq from "./imgComponents/imgCardioInfantilsq"
import ImgChangePainsq from "./imgComponents/imgChangePainsq"
import ImgEcancersq from "./imgComponents/imgEcancersq"
import ImgAsocupac from "./imgComponents/imgAsocupac"
import ImgValleDelLili from "./imgComponents/imgValleDelLili"
import ImgClinicaDelNorte from "./imgComponents/imgClinicaDelNorte"
import ImgScare from "./imgComponents/imgScare"
import ImgCruzRoja from "./imgComponents/imgCruzRoja"
import ImgAcpp from "./imgComponents/imgAcpp"
import ImgSnt from "./imgComponents/imgSnt"

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

const StudiesComplementaryComponent = () => {
  const { t, i18n } = useTranslation()

  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <Box mt={1}></Box>
      <h2 className="titlesGradientH2">{t("studies.complementarytitle")}</h2>
      <Divider />
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgCardioInfantilsq />}
          title={t("hospital.cardioinfantil")}
          subheader={t("studies.complementary.date011")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.cardioinfantil011")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgChangePainsq />}
          title={t("studies.complementary.changepain")}
          subheader={t("studies.complementary.date010")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.changepain010")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgEcancersq />}
          title={t("studies.complementary.ecancer")}
          subheader={t("studies.complementary.date009")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.changepain009")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgAsocupac />}
          title={t("studies.complementary.asocupac")}
          subheader={t("studies.complementary.date008")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.asocupac008")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgClinicaDelNorte />}
          title={t("hospital.clinicadelnorte")}
          subheader={t("studies.complementary.date007")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.clinicadelnorte007")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgScare />}
          title={t("studies.complementary.scare")}
          subheader={t("studies.complementary.date006")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.scare006")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgInc />}
          title={t("hospital.inc")}
          subheader={t("studies.complementary.date005")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.inc005")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgInc />}
          title={t("hospital.inc")}
          subheader={t("studies.complementary.date004")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.inc004")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgCruzRoja />}
          title={t("studies.complementary.cruzroja")}
          subheader={t("studies.complementary.date003")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.cruzroja003")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgAcpp />}
          title={t("studies.complementary.acpp")}
          subheader={t("studies.complementary.date002")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.acpp002")}
          </Typography>
        </CardContent>
      </Card>
      <br />

      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgSnt />}
          title={t("studies.complementary.snt")}
          subheader={t("studies.complementary.date001")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.complementary.snt001")}
          </Typography>
        </CardContent>
      </Card>
      <br />
    </div>
  )
}

export default StudiesComplementaryComponent
